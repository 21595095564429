import React, { useRef, useState, useEffect } from "react";
import { FormControl, FormLabel, FormControlLabel } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Radio, RadioGroup } from "@mui/material";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import * as safe from "../utilities/safe";
import { handleMatomoPageview } from "../utilities/bookkeeping";

//	LoginDialog is used for both initial account setup and for changing
//	password & period for password check
//
//	Login is driven by 3 props and 2 items in local storage, and
//	these must have some consistency
//		props:	open		-- boolean from App controlling opening the dialog
//				firstLogin	-- storage items 'b' and 'f' should be empty
//								if true, present password options
//								if !firstLogin && !editLogin, confirm password
//				beta		-- beta mode requires a special password and local
//								storage 'f'; otherwise 'b' is used to hold pw hash
//				editLogin	-- if set, might proceed in two stages
//								1) if storage holds a password, confirm pw, then
//								2) proceed like firstLogin to set all parameters
//								(beta mode still requires a password to be used)
//								Cancel button is enabled in this mode.
//	useEffect is only used to obfuscate the flow of password validation

// TODO: on editLogin retrieve more account data from LocalStorage: confirmation period

export default function LoginDialog(props) {
	//console.log("LoginDialog props...");
	//console.log(props);
	//const { beta, open, onClose, firstLogin, editLogin } = props; // beta mode forces use of password
	let firstLogin = props.firstLogin === true;
	let beta = props.beta === true;
	let editLogin = props.editLogin === true;
	if (firstLogin && editLogin) {
		console.error("ERROR - Login illegal props");
	}
	// console.log(i18next.languages);
	const [usePassword, setUsePassword] = useState(beta);
	const [loginReady, setLoginReady] = useState(false); // ensure useEffect runs before showing UI
	const [ready, setReady] = useState(!editLogin); // ready to close, valid data
	const [period, setPeriod] = useState(editLogin ? "always" : "weekly"); // starting value, can change
	const [loginError, setLoginError] = useState(false);
	const [doingCheck, setDoingCheck] = useState(!firstLogin && !editLogin); // updated in useEffect
	const [pValue, setPValue] = useState("");
	const pw = useRef("");
	const { t } = useTranslation();
	const item = beta ? "f" : "b";
	const confirmFunction = useRef(null);

	const hasLoaded = i18next.languages && i18next.languages.length > 0;
	const [transLoaded, setTransLoaded] = useState(hasLoaded);

	i18next.on("loaded", (loaded) => {
		setTransLoaded(true);
		console.log(loaded);
	});
	let storage = window["localStorage"]; // useEffect to check if a password exists

	function getTitle() {
		if (firstLogin || (editLogin && !doingCheck)) {
			handleMatomoPageview("AccountSetup", "Account Setup");
			return t("AccountSetup");
		} else {
			handleMatomoPageview("Login", "Login");
			return t("Login");
		}
	}
	const usePwChange = (event) => {
		// track the Use Password toggle to controll visibility of other form items
		//console.log("usePW "+event.target.value);
		if (beta) return; // ignore trying to set to 'No Password'
		pw.current = "";
		setUsePassword(event.target.value === "yes");
		setReady(event.target.value === "no" || (pw.current && pw.current.length > 4));
	};
	const changePeriod = (event) => {
		// track password re-confirmation period
		setPeriod(event.target.value);
	};
	const asterisks = "************************";
	const pwChange = (event) => {
		// track password changes, set 'ready' when length > 3
		// behave like as password field, but without telling browser it is one
		//    by replacing each character with an asterisk after capturing it
		const value = event.target.value;
		if (value.length > pw.current.length) {
			// added a character (or more)
			pw.current = pw.current + event.target.value.substring(pw.current.length); // move to internal
		} else if (value.length === pw.current.length) {
			console.log(value + " same length " + event.nativeEvent.data); // check if delete+new ?
		} else {
			pw.current = pw.current.substring(0, value.length); // deleted a char, shrink internal
		}
		setPValue(asterisks.substring(0, pw.current.length));
		setLoginError(false);
		setReady(pw.current.length > 3); // minimum password length is 4
		//console.log(value+" "+pw.current);
		//console.log(event);
	};
	const handleCancel = () => {
		props.onClose(null, null);
	};
	const handleKey = (event) => {
		// treat password return 'CR' as a done / close event
		if (event.keyCode === 13 && pw.current.length > 3) handleClose();
	};
	const handleClose = () => {
		console.log("handle close with " + pw.current);
		if (doingCheck || beta) {
			async function checkLogin(p) {
				let valid = p && p.length > 3;
				if (valid) valid = await confirmFunction.current(p);
				console.log("valid " + valid);
				if (!valid) {
					setLoginError(true);
					console.log("wrong password ********* !!!");
					setPValue("");
					handleMatomoPageview(
						"AccountSetup",
						"Account Setup: Wrong Password"
					);
					await new Promise((resolve) => {
						setTimeout(() => {
							pw.current = "";
							setPValue("");
							resolve();
						}, 3000); // impose 3 second delay to try again
					});
				} else {
					setLoginError(false);
					if (editLogin) {
						setDoingCheck(false);
					} else {
						console.log("login closing...");
						props.onClose(pw.current, period);
					}
				}
			}
			checkLogin(pw.current);
		} else {
			props.onClose(pw.current, period);
		}
	};

	useEffect(() => {
		// if (editLogin) {
		// 	let test = storage.getItem(item);
		// 	doingCheck.current = test !== null;
		// } else doingCheck.current = !firstLogin;

		setDoingCheck(editLogin ? storage.getItem(item) !== null : !firstLogin);
		confirmFunction.current = beta ? safe.auth : safe.confirm;
		setLoginReady(true);
	}, [beta, editLogin, firstLogin, item, storage]);

	const usePwYesNo = beta ? "yes" : "no";
	console.log("error? " + loginError);
	return loginReady && transLoaded ? (
		<Dialog onClose={handleClose} open={props.open} fullScreen>
			<DialogTitle sx={{ p: 5, m: 0 }}>{getTitle()}</DialogTitle>
			<Box sx={{ px: 4 }}>
				{(firstLogin || (editLogin && !doingCheck)) && (
					<FormControl>
						<FormLabel for="group-1">{t("LoginType")}</FormLabel>
						<RadioGroup
							defaultValue={usePwYesNo}
							name="group-1"
							onChange={usePwChange}
						>
							<FormControlLabel
								value="no"
								control={<Radio />}
								label={t("NoPassword")}
							/>
							<FormControlLabel
								value="yes"
								control={<Radio />}
								label={t("Password")}
							/>
						</RadioGroup>
					</FormControl>
				)}
				{(firstLogin || (editLogin && !doingCheck)) &&
					usePassword && ( // show password field and renewal options
						<Box sx={{ px: 2 }}>
							<Box sx={{ p: 1 }}>
								<FormLabel for="group-2" sx={{ mt: 2, p: 1 }}>
									Check:
								</FormLabel>
								<RadioGroup
									defaultValue={period}
									name="group-2"
									onChange={changePeriod}
								>
									<FormControlLabel
										value="always"
										control={<Radio />}
										label={t("EveryLogin")}
									/>
									<FormControlLabel
										value="weekly"
										control={<Radio />}
										label={t("Weekly")}
									/>
									<FormControlLabel
										value="monthly"
										control={<Radio />}
										label={t("Monthly")}
									/>
								</RadioGroup>
							</Box>
							<TextField
								required
								autoFocus
								label={t("Required")}
								size="small"
								value={pValue}
								error={loginError}
								onChange={pwChange}
								onKeyUp={handleKey}
							></TextField>
						</Box>
					)}
				{doingCheck && (
					// assume not showing unless something is needed
					<Box sx={{ px: 2 }}>
						<TextField
							required
							autoFocus
							sx={{ mt: 4 }}
							label={t("Password")}
							size="small"
							value={pValue}
							error={loginError}
							onChange={pwChange}
							onKeyUp={handleKey}
						></TextField>
					</Box>
				)}
				<Box>
					<Button
						variant="text"
						size="large"
						onClick={handleClose}
						disabled={!ready}
						sx={{ px: 2 }}
					>
						{" "}
						{t("Continue")}
					</Button>
					{editLogin && (
						<Button
							variant="text"
							size="large"
							onClick={handleCancel}
							disabled={false}
							sx={{ px: 4 }}
						>
							{" "}
							{t("Cancel")}
						</Button>
					)}
				</Box>
			</Box>
		</Dialog>
	) : (
		<div
			dangerouslySetInnerHTML={{
				__html: "<span class='loader'></span>"
			}}
		/>
	);
}
