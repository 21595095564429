import React, { useRef, useEffect, useState } from "react";
import Box from "@mui/material/Box";
//import Videojs from "video.js";
import "../styles/index.css";
import "../styles/customize-progress-bar.css";

<link href="https://vjs.zencdn.net/8.16.1/video-js.css" rel="stylesheet" />;

const VideoTest = React.forwardRef((props, ref) => {
	let nodeRef = useRef(JSON.parse(JSON.stringify(props.node)));
	const [data, setData] = useState("<span class='loader'></span>");

	// const videoJsOptions = {
	// 	autoplay: false,
	// 	playbackRates: [0.5, 1, 1.25, 1.5, 2],
	// 	width: 720,
	// 	height: 300,
	// 	controls: true,
	// 	sources: [
	// 		{
	// 			src: "//vjs.zencdn.net/v/oceans.mp4",
	// 			type: "video/mp4"
	// 		}
	// 	]
	// };
	let video = useRef();

	useEffect(() => {
		// runs after render to start fetching data
		video.current = document.getElementById("hlmdVideo");

		// function canPlay() {
		// 	//audio is ready to play
		// 	//video.current.play();
		// 	console.log("play started");
		// }
		// function canPlayThrough() {
		// 	//audio is ready to play all the way through
		// 	console.log("can play through");
		// }

		try {
			let file = nodeRef.current.file;
			let href = file.startsWith("http") ? file : "/testFiles/" + file;
			console.log("href " + href);
			if (href) setData(href); // triggers a re-render with new data
			// video.current.addEventListener("canplay", canPlay);
			// video.current.addEventListener("canplaythrough", canPlayThrough);
			// video.current.src = href;
			// video.current.load();

			//recent.update(nodeRef.current);
			// });
		} catch (error) {
			console.error(error.message);
			setData("<p style='margin:18px; color:DarkRed'>" + error.message + "</p>");
		}
		// if (!nodeRef.current) {
		// 	// bookkeeping
		// 	// recent.update(nodeRef.current); // initial history update even though
		// }
		// return () => {
		// 	video.current.removeEventListener("canplay", canPlay, { passive: true });
		// 	video.current.removeEventListener("canplaythrough", canPlayThrough, {
		// 		passive: true
		// 	});
		// };
	}, [props.node]);
	console.log(data);
	
	return (
		<div {...props} ref={ref}>
			<Box sx={{ py: 4 }}>
				{" "}
				{/* smaller padding since floating div above consumes space */}
				<br></br>
				{/* <div className='audio-player'>
                    <div className='inner'>
                        <AudioDisplayTrack {...{ currentTrack, audioRef, setDuration, progressBarRef }} />
                        <Controls {...{ audioRef, progressBarRef, duration, setTimeProgress }} />
                        <ProgressBar {...{ progressBarRef, audioRef, timeProgress, duration }} />
                    </div>
                </div> */}
			</Box>

			<Box sx={{ py: 4 }}>
				{" "}
				<div>
					<video
						id="my-player"
						class="video-js"
						controls
						preload="auto"
						data-setup="{}"
					>
						<source
							src="https://pworker1.chipwatson.workers.dev/MyClips/Recital.mp4"
							type="video/mp4"
						></source>

						<p class="vjs-no-js">
							To view this video please enable JavaScript, and consider
							upgrading to a web browser that supports HTML5 video
						</p>
					</video>
				</div>
				{/* <div>
					<Videojs {...videoJsOptions} />
				</div> */}
				{/* smaller padding since floating div above consumes space */}
				{/* <video id="hlmdVideo" controls width="100%" height="100%" /> */}
			</Box>
		</div>
	);
});

export default React.memo(VideoTest);
