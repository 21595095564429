// Acknowledgements:
// https://www.freecodecamp.org/news/how-to-build-mobile-swiping-component-in-react/

// might need to manually perform a vertical scroll since we are taking the touch events

import { useCallback, useEffect, useState, useRef } from "react";

export default function MobileSwiper({ children, onSwipe }) {
	const wrapperRef = useRef(null);
	const [startX, setStartX] = useState(0);
	const [startY, setStartY] = useState(0);

	const handleTouchStart = useCallback((e) => {
		if (!wrapperRef.current.contains(e.target)) {
			return;
		}
		// e.preventDefault(); // can't prevent default unless registration is active
		console.log("touch");
		setStartX(e.touches[0].clientX);
		setStartY(e.touches[0].clientY);
	}, []);

	const handleTouchEnd = useCallback(
		(e) => {
			if (!wrapperRef.current.contains(e.target)) {
				return;
			}
			// e.preventDefault(); // can't prevent default unless registration is active
			console.log("touch end");
			const endX = e.changedTouches[0].clientX;
			const endY = e.changedTouches[0].clientY;
			const deltaX = endX - startX;
			const deltaY = endY - startY;

			onSwipe({ deltaX, deltaY });
		},
		[startX, startY, onSwipe]
	);

	useEffect(() => {
		window.addEventListener("touchstart", handleTouchStart);
		window.addEventListener("touchend", handleTouchEnd);

		return () => {
			window.removeEventListener("touchstart", handleTouchStart);
			window.removeEventListener("touchend", handleTouchEnd);
		};
	}, [handleTouchStart, handleTouchEnd]);

	return <div ref={wrapperRef}>{children}</div>;
}
