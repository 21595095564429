import React from "react";
import { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useHistory } from "../context/HistoryContext.js";
// import * as recent from "../utilities/recent.js";
import * as database from "../utilities/database.js";
import * as io from "../utilities/io.js";
<link rel="manifest" href="/manifest.json"></link>;

// This component presents all of its pages one by one.   It is viewed as
// a leaf node in the WebCatalog in that it has no .contents, instead
// its "children" are in .pages, an array similar to .contents
// For now, support is only provide to html children (files), although
// that could be extended via the ItemPresenter component

// steps:
//  1. fetch 1st page, setMaxPage(0), save to cache & repeat for other pages
//  2. display the current page's contents in a box
//  3. add navigation components >, <, << as appropriate
//  4. handle page changes

const PagesPresenter = React.forwardRef((props, ref) => {
	const history = useHistory();
	const [page, setPage] = useState(0);
	const [maxPage, setMaxPage] = useState(-1);
	const pages = useRef([]); // to hold the pages

	let node = history.state().n; // handle navigation back to here
	if (!node) {
		// if not navigation, use props.node
		node = JSON.parse(JSON.stringify(props.node)); // get a copy of the node
		history.replaceState({ ...history.state(), n: node }); // save for later
	}
	let lastPage = node.pages ? node.pages.length - 1 : -1;
	let doneLabel = props.doneLabel ? props.doneLabel : node.doneLabel;
	if (doneLabel === undefined) doneLabel = null;
	const hasDoneHandler = doneLabel !== null;

	console.log("PagesPresenter... " + doneLabel);

	const nextPage = () => {
		onPageChange(1);
	};
	const prevPage = () => {
		onPageChange(-1);
	};
	// const firstPage = () => {
	// 	onPageChange(0);
	// };
	function onPageChange(move) {
		let nextPage = page;
		if (move === 1) {
			if (nextPage < lastPage) nextPage += 1;
		} else if (move === -1) {
			if (nextPage > 0) nextPage -= 1;
		} else nextPage = 0; // move to page 0
		if (nextPage !== page) {
			setPage(nextPage);
		}
	}

	useEffect(() => {
		let getFiles = async () => {
			for (let i = 0; i < node.pages.length; i++) {
				try {
					let file = node.pages[i].file;
					console.log(" fetching " + file);
					let result = await io.getItem(file); // TODO: modify so caller knows if retrieved from database?
					if (result) {
						if (result.startsWith("offline")) {
							// do nothing for now, just display the message from service worker
						} else {
							if (!result.startsWith('<br data-hlm-type="ok">')) {
								// start background task to cache the file, returns a promise quickly
								result = '<br data-hlm-type="ok">\n' + result;
								await database.putToDB("itemCache", file, result);
							}
						}
						let temp = pages.current;
						temp.push(result);
						pages.current = temp;
						setMaxPage(i);
						//recent.update(nodeRef.current); // initial history update even though not rendered
					}
				} catch (error) {
					console.error(error.message);
					let temp = pages.current;
					temp.push(
						"<p style='margin:18px; color:DarkRed'>" + error.message + "</p"
					);
					pages.current = temp;
				}
			}
		};
		if (maxPage < 0) getFiles();
	}, [maxPage, node.pages]);

	useEffect(() => {
		let scrollY = history.state().s;
		if (scrollY) window.scrollTo({ top: scrollY });
	}, [history]);

	useEffect(() => {
		var timer = null;
		const handleScroll = (event) => {
			if (!timer) timer = setTimeout(processScroll, 500); // at most 2x per second
		};
		const processScroll = () => {
			history.replaceState({ ...history.state(), s: window.scrollY });
			timer = null;
			// console.log(window.scrollY);
			// TODO: update history if scroll increases by 1/2 window
		};
		window.addEventListener("scroll", handleScroll, { passive: true });
		return () => {
			window.removeEventListener("scroll", handleScroll);
			if (timer) {
				clearTimeout(timer);
			}
		};
	}, [history]);

	let thisPage = pages.current[page];
	let direction = page > 0 ? "row" : "row-reverse"; // if 0 only need rightmost button
	return (
		<>
			{maxPage >= 0 ? (
				<Box sx={{ width: "100%", py: 8, px: 2, overflow: "hidden" }}>
					{" "}
					<div dangerouslySetInnerHTML={{ __html: thisPage }} />{" "}
					<Stack direction={direction} justifyContent="space-between">
						{page > 0 && (
							<Button variant="contained" onClick={prevPage}>
								{"< Previous"}
							</Button>
						)}
						{page < maxPage && (
							<Button variant="contained" onClick={nextPage}>
								{"Next >"}
							</Button>
						)}
						{page === maxPage && hasDoneHandler && (
							<Button variant="contained" onClick={props.onDone}>
								{doneLabel}
							</Button>
						)}
					</Stack>
				</Box>
			) : (
				<div
					dangerouslySetInnerHTML={{
						__html: "<span class='loader'></span>"
					}}
				/>
			)}
		</>
	);
});

export default PagesPresenter;
