import { createContext, useContext, useRef } from "react";

const HistoryContext = createContext();

const HistoryProvider = ({ children }) => {
	console.log("HistoryProvider");
	const stack = useRef([{ p: -1 }]);
	const index = useRef(0);

	function state() {
		return stack.current[index.current];
	}

	function length() {
		return stack.current.length;
	}

	function replaceState(newState) {
		stack.current[index.current] = { ...newState }; // shallow copy
	}

	function pushState(newState) {
		console.log("push page " + newState.p + " id:" + newState.id);
		index.current += 1;
		stack.current[index.current] = { ...newState };
		if (index.current < stack.current.length - 1)
			stack.current.length = index.current + 1;
	}

	function pop() {
		if (index.current === 0) return null;
		let result = stack.current[index.current];
		index.current -= 1;
		console.log("pop page p:" + result.p + " id:" + result.id);
		return result;
	}

	function go(direction) {
		if (direction === -1) return pop();
		if (direction !== 1) return null;
		if (index.current === stack.current.length - 1) return null;
		index.current += 1;
		return stack.current[index.current];
	}

	function report() {
		// create multi-line internal history report
		let report =
			"History: state " +
			JSON.stringify(stack.current[index.current]) +
			" length " +
			stack.current.length +
			" index " +
			index.current;
		if (stack.current.length > 1)
			for (let i = 0; i < stack.current.length; i++) {
				report += "\n    " + JSON.stringify(stack.current[i]);
			}
		return report;
	}

	const history = { state, length, replaceState, pushState, pop, go, report };

	return (
		// <HistoryContext.Provider value={{ stack, index, history }}>
		<HistoryContext.Provider value={history}>{children}</HistoryContext.Provider>
	);
};

const useHistory = () => {
	return useContext(HistoryContext);
};

export { HistoryProvider, useHistory };

export default HistoryContext;
