import React, { useRef, useState, useEffect } from "react";
import {
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	ListSubheader,
	ListItemSecondaryAction,
	Checkbox,
	IconButton,
	Collapse,
	Grid
} from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ChevronRight from "@mui/icons-material/ChevronRight";
import ChecklistIcon from "@mui/icons-material/Checklist";
import CancelIcon from "@mui/icons-material/Cancel";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { grey } from "@mui/material/colors";
import Checkbox2 from "./Checkbox2.js";
import * as safe from "../utilities/safe.js";
import { useHistory } from "../context/HistoryContext";

<link rel="manifest" href="/manifest.json"></link>;

// 3 main routines are called recursively to render a json tree as a set of nested lists
//
// CatalogList => top level externally callable widget which starts the recursive
//   build of the nested list of lists, and handles restarting from another point in
//   in the tree (for now, a node with lots of leaves)
//
// CatalogBranch (List wrapper + 1 ListItem controlling children，then calls CatalogChildren)
//   (parent) node has 3 states:
//       (1) unexpanded -- only possible action is open
//       (2) open -- displays children and adds an 'edit / select children' option
//       (3) open+edit -- replaces commands with a caller supplied action and a 'cancel edit' action
//           this mode shows a select button to the left of parent (select-all) and all children
//
// CatalogLeaf (ListItem)
//   children have states 2 and 3, invisible in 1
//       (2) normal -- shows name and a caller supplied state or action
//           2 actions possible: select (click on name or right arrow), and 'action'; both supplied by caller
//           if offline and item is not cached, "select" is disabled
//       (3) edit -- shows select button to the left, removes action buttons, but still shows caller
//           provided state relevant to the 'action' for this item, if any
//           TODO: grey out the nodes which have already been acted upon
//
// For both item types, the primary action (clicking on the name) is to select an item for presentation or action.
// Expected caller provided actions are 'download' and 'delete' (un-download), depending upon which json structure
//   is being presented (web catalog or local catalog).
//
// Caller provides
//   catalog   the json tree structure to display
//   onSelect    function to call when an item is selected for presentation
//   actionIcons    a function returning React icons to display for the auxillary command
//                parent uses icons[0], items use [icon[status]] where status is enum returned by...
//                TODO: pass the icons as an array?
//   nodeStatus      a function returning file staging status of the leaves of a node
//   actionFunction  a function to which array of nodes is passed to perform an action
//                 e.g. download, or delete, (possibly someday even a subtree), returning a Promise which
//                 resolves to an array of results
//

// this component supports having more than one secondary action (2 are needed for Catalog's capabilities)
const CustomContainerComponent = React.forwardRef(function CustomContainerComponent(
	{ children, extraSecondaryAction, ...other },
	ref
) {
	return (
		<li ref={ref} {...other}>
			{children}
			{extraSecondaryAction}
		</li>
	);
});

function CatalogBranch(props, ref) {
	let node = props.node;
	let depth = props.depth;
	let getStatus = props.getStatus;
	let getAvailability = props.getAvailability;
	let thisOffline = props.offline;
	const bigNode = node.contents && node.contents.length && node.contents.length > 3;
	const startOpen = depth < 1 || (!bigNode && depth < 2);
	const [offline, setOffline] = useState(props.offline); // capture on first entry
	const [open, setOpen] = useState(startOpen); // state of this node (parent), passed to onClick
	const [edit, setEdit] = useState(false); // state of the edit mode for this node (parent), passed to children
	const [masterSelect, setMasterSelect] = useState(false); // state of indicator selecting or clearing all children
	const checked = useRef([]); // vector of checked children, initially empty
	const [noneChecked, setNoneChecked] = useState(true); // true when all children are not selected
	const [rerender, setRerender] = useState(0); // use to trigger a re-render when action promise resolves
	const [dataReady, setDataReady] = useState(false);
	const hashes = useRef(null); // used as a 2 phase lock
	const hasLeaf = useRef(false);

	if (depth === 0 && !open) setOpen(true);
	let mustRerender = props.offline !== offline;
	// console.log("branch " + node.name + " " + rerender);
	// console.log("CatalogBranch " + node.name + " depth " + depth + " open " + open);
	// console.log("  offline " + offline + " dataReady " + dataReady +
	// console.log("  mustRerender " + mustRerender);

	const childToggled = (id) => {
		let wasEmpty = checked.current.length === 0;
		const currentIndex = checked.current.indexOf(id);
		const setOn = currentIndex === -1; // derive state of child
		if (setOn) {
			checked.current.push(id); // add if missing
		} else {
			checked.current.splice(currentIndex, 1); // remove if there
		}
		let isEmpty = checked.current.length === 0;
		if (isEmpty !== wasEmpty) setNoneChecked(isEmpty);
	};
	const setCheckboxes = (setOn) => {
		let wasEmpty = checked.current.length === 0;
		node.contents.forEach((child) => {
			let index = checked.current.indexOf(child.id);
			let isOff = index === -1;
			if (setOn && isOff) {
				checked.current.push(child.id);
			} else if (!setOn && !isOff) {
				checked.current.splice(index, 1);
			}
		});
		let isEmpty = checked.current.length === 0;
		if (isEmpty !== wasEmpty) setNoneChecked(isEmpty);
	};
	function handleOpenClose() {
		// console.log("handleOpenClose while open is " + open + " depth " + depth);
		if (bigNode && depth > 0) {
			// nodes with many children are not opened inline
			props.pushStart(node, setOpen); // restart with this node at top
			return; // 'setOpen' allows app bar back '<' to reset this
		} else if (bigNode) {
			props.handleBack();
		}
		setOpen(!open);
	}
	function onBranchAction() {
		// console.log("onBranchAction "+node.name);
		let nodes = [];
		node.contents.forEach((child) => {
			if (checked.current.indexOf(child.id) !== -1) nodes.push(child);
		});
		let [p0, p1] = props.onAction(nodes); // start process all checked children
		p0.then(() => {
			// downloads staged but not complete
			// console.log("p0 done");
			setEdit(false); // cancel edit mode
			updateChildrenStatus(); // refresh status
			setRerender((rerender | 31) + 1); // trigger full re-render for now
		});
		p1.then(() => {
			// console.log("action(s) complete " + node.name);
			updateChildrenStatus(); // refresh status
			setRerender((rerender | 31) + 2); // value captured at closure creation
		});
	}
	function onLeafAction(leaf) {
		let [p0, p1] = props.onAction([leaf]);
		// console.log("leaf action launched");
		p0.then(() => {
			setRerender((rerender | 31) + 1);
			updateChildrenStatus(); // refresh status
			// console.log("leaf action p0 done " + leaf.name);
		});
		p1.then(() => {
			// console.log("leaf action p1 done");
			updateChildrenStatus(); // refresh status
			setRerender((rerender | 31) + 2); // value captured at closure creation
		});
	}
	function updateChildrenStatus() {
		const status = getStatus ? getStatus(node) : null;
		const children = node.contents;
		let pending = 0;
		if (status) {
			for (let i = 0; i < children.length; i++) {
				children[i].status = status[i];
				if (!thisOffline && status[i] === 1) pending++;
			}
			if (pending > 0) {
				// console.log(pending + " pending");
				setTimeout(forceUpdate, 2000);
			}
		}
	}
	function forceUpdate() {
		setRerender((rerender | 31) + 1);
	}
	// compute derived data for children components
	let subheader = depth < 1 && node.contents && node.contents.length > 3;
	let children = node.contents;
	useEffect(() => {
		// console.log("useEffect " + node.name + " " + node.id);
		// let count = 0;
		if (children && children.length > 0) {
			// set children options except availability
			let parentData = JSON.parse(JSON.stringify(node)); // propagate metadata to children
			delete parentData.contents; // remove tree structure; only keep what is useful to child
			const status = getStatus ? getStatus(node) : null;
			// console.log(status);
			for (let i = 0; i < children.length; i++) {
				let child = children[i];
				child.status = status ? status[i] : 0;
				if (child.status >= 0) {
					// child is a visible leaf
					if (!child.contents) {
						// count++;
						hasLeaf.current = true;
						child.parent = parentData; // cheap back link, no circular references
					}
				}
			}
			// console.log("#good children " + count);
		}
		function setChildrenAvailable(available) {
			for (let i = 0; i < children.length; i++) {
				let child = children[i];
				child.available = available ? available[i] : true;
			}
			// if (children.length === 3) {
			//     console.log(children); // picks only 1 branch of current catalog
			// }
		}
		if (thisOffline && hasLeaf.current && hashes.current === null) {
			hashes.current = []; // this block runs only once
			// console.log("branch offline "+node.name);
			let hashPromise = new Promise(async function (resolve, reject) {
				let h = [];
				// console.log("getting hashes "+node.name);
				for (let i = 0; i < node.contents.length; i++) {
					let child = node.contents[i];
					let hash = await safe.digest(child.file);
					h.push(hash);
				}
				hashes.current = h;
				resolve();
			});
			hashPromise.then(() => {
				const available = getAvailability
					? getAvailability(hashes.current)
					: null;
				setChildrenAvailable(available);
				if (!dataReady) {
					setDataReady(true); // normal completion
				} else {
					setRerender((rerender | 31) + 1);
				} // completion if got hashes after first view
				// console.log("dataReady1 "+node.name);
			});
		} else if (
			(!dataReady || mustRerender) &&
			hasLeaf.current &&
			(!thisOffline || (hashes.current && hashes.current.length > 0))
		) {
			const available =
				thisOffline && getAvailability ? getAvailability(hashes.current) : null;
			setChildrenAvailable(available);
			setDataReady(true);
			// console.log("dataReady2 "+node.name+" "+available);
		} else if (!dataReady && !hasLeaf.current) {
			setDataReady(true);
			// console.log("dataReady3 "+node.name);
		}
		if (mustRerender) setOffline(thisOffline);
	}, [
		node,
		thisOffline,
		getStatus,
		getAvailability,
		children,
		dataReady,
		mustRerender,
		props.offline,
		rerender
	]);

	// console.log(
	// 	"start open " +
	// 		open +
	// 		" dataReady " +
	// 		dataReady +
	// 		" hasLeaf.current " +
	// 		hasLeaf.current
	// );
	// if (node.contents) console.log(node.contents);
	// some button icons are conditional on the state of the parent (this node) or the status of a child

	return (
		<List
			key={"nested-list-" + node.id}
			component="nav"
			sx={{ width: "100%", maxWidth: 390, color: "primary.main" }}
		>
			{subheader && (
				// subheader initially tucks itself under the toolbar
				<ListSubheader
					component="div"
					id={"nested-list-subheader-" + node.id}
					sx={{ color: "primary.main", mt: -8, pb: 1, pt: 0 }}
				>
					{node.description}
				</ListSubheader>
			)}
			<ListItem
				button
				id={node.id}
				disableRipple
				ContainerComponent={CustomContainerComponent}
				ContainerProps={{
					// the extraSecondaryAction below will appear at the end of the row
					extraSecondaryAction: (
						<ListItemSecondaryAction>
							{!edit ? (
								<IconButton
									onClick={handleOpenClose}
									sx={{ color: "primary.main" }}
								>
									{open ? <ExpandLess /> : <ExpandMore />}
								</IconButton>
							) : (
								<IconButton
									onClick={(event) => {
										setEdit(false);
									}}
								>
									<CancelIcon
										sx={{ color: "primary.light" }}
										style={{ transform: "scale(0.9)" }}
									/>
								</IconButton>
							)}
						</ListItemSecondaryAction>
					)
				}}
				sx={{ ml: 3 * depth, my: "1px", py: "1px" }}
				onClick={(event) => {
					// primary action invoked by clicking on name or checkbox if shown
					if (edit) {
						// treat as click on the left 'check-all' toggle
						const newAll = !masterSelect;
						setMasterSelect(newAll); // passed to children
						setCheckboxes(newAll); // tracks computed checked state of children
					} else {
						handleOpenClose();
					}
				}}
			>
				{edit && (
					<ListItemIcon sx={{ minWidth: 36 }}>
						<Checkbox
							edge="start"
							disableRipple
							checked={masterSelect}
							icon={
								<RadioButtonUncheckedIcon sx={{ color: grey[500] }} />
							}
							checkedIcon={
								<CheckCircleOutlineIcon
									sx={{ color: "primary.light" }}
								/>
							}
							sx={{ mx: "0px", p: "0px" }}
							style={{ transform: "scale(0.9)" }}
						/>
					</ListItemIcon>
				)}
				<ListItemText
					primary={node.name}
					sx={{
						lineHeight: 2,
						my: 0
					}}
				/>
				{/* must have one ListItemSecondaryAction here to have the extra one visible */}
				<ListItemSecondaryAction
					sx={{ py: "1px" }}
					style={{ right: "13%", left: "auto" }}
				>
					{open && !edit && hasLeaf.current && (
						<IconButton
							onClick={(event) => {
								setEdit(true);
							}}
						>
							<ChecklistIcon />
						</IconButton>
					)}
					{open && edit && hasLeaf.current && (
						<IconButton
							onClick={(event) => {
								onBranchAction(node, checked);
							}}
							disabled={noneChecked}
							sx={{ color: "primary.main" }}
						>
							{props.actionIcons[offline ? 0 : 1][0]}
						</IconButton>
					)}
				</ListItemSecondaryAction>
			</ListItem>
			{(startOpen || !bigNode) && (
				<Collapse in={open} timeout="auto">
					{dataReady ? (
						<>
							{children.map((child) => {
								if (child.status >= 0) {
									if (child.contents) {
										return (
											<CatalogBranch
												node={child}
												key={"CB-" + child.id}
												depth={depth + 1}
												pushStart={props.pushStart}
												getStatus={props.getStatus}
												offline={props.offline}
												getAvailability={props.getAvailability}
												onSelect={props.onSelect}
												onAction={props.onAction}
												actionIcons={props.actionIcons}
											/>
										);
									} else {
										return (
											<CatalogLeaf
												node={child}
												key={"CL-" + child.id}
												depth={depth + 1}
												edit={edit}
												initial={masterSelect}
												onCheck={childToggled}
												offline={props.offline}
												disabled={!child.available}
												onSelect={props.onSelect}
												actionStatus={child.status}
												onAction={onLeafAction}
												actionIcons={props.actionIcons}
											/>
										);
									}
								} else {
									return <div key={"EMPTY-" + child.id}></div>;
								}
							})}
						</>
					) : (
						<div
							key={"SPIN-" + node.id}
							dangerouslySetInnerHTML={{
								__html: "<span class='loader'></span>"
							}}
						/>
					)}
				</Collapse>
			)}
		</List>
	);
}

function CatalogLeaf(props, ref) {
	let node = props.node;
	let edit = props.edit;
	let actionDisabled = node.status > 0;
	let disabled = edit ? actionDisabled : props.disabled && props.offline;
	// console.log("Leaf " + node.name + " avail " + node.available + " " + node.status);
	let icons = props.actionIcons[props.offline ? 0 : 1];
	let status = node.status ? node.status : 0;
	let actionIcon = icons[status];

	let extraActionSpec = props.edit
		? {}
		: {
				extraSecondaryAction: (
					<ListItemSecondaryAction>
						<IconButton
							onClick={(event) => {
								props.onSelect(node);
							}}
							disabled={disabled}
							sx={{ color: "primary.main" }}
						>
							<ChevronRight />
						</IconButton>
					</ListItemSecondaryAction>
				)
			};
	let myColor = disabled ? "#000000" : "primary.main"; // pale primary isn't so good
	return (
		<ListItem
			button
			id={node.id}
			key={node.id}
			disableRipple
			ContainerComponent={CustomContainerComponent}
			ContainerProps={extraActionSpec}
			sx={{ ml: 3 * props.depth, my: "1px", py: "1px", color: myColor }}
			disabled={disabled}
			onClick={(event) => {
				// list item's primary action is checkbox in edit mode, select otherwise
				if (edit) {
					// Checkbox2 controls own state, for much slower
					// mui use instead: toggleCheckbox(checked,setChecked,node.id);
				} else {
					if (node.available) props.onSelect(props.node);
				}
			}}
		>
			{edit && (
				<ListItemIcon sx={{ py: 0, minWidth: 36 }}>
					<Checkbox2
						initial={props.initial}
						onCheck={props.onCheck}
						id={node.id}
					/>
					{/* <Checkbox edge="start" disableRipple
                        checked={checked.indexOf(node.id) !== -1}
                        icon={<RadioButtonUncheckedIcon sx={{color: grey[500]}}/>}
                        checkedIcon={<CheckCircleOutlineIcon />}
                        sx={{mx:"0px", p:"0px"}}
                        style={{transform: "scale(0.9)"}}
                    /> */}
				</ListItemIcon>
			)}
			<ListItemText primary={node.name} sx={{ mx: "0px", px: "0px" }} />
			{!edit && (
				<ListItemSecondaryAction style={{ right: "15%", left: "auto" }}>
					<IconButton
						disabled={actionDisabled}
						onClick={(event) => {
							props.onAction(node);
						}}
						sx={{ color: "primary.main" }}
						style={{ transform: "scale(0.85)" }}
					>
						{actionIcon}
					</IconButton>
				</ListItemSecondaryAction>
			)}
		</ListItem>
	);
}

const CatalogList = React.forwardRef((props, ref) => {
	// props.catalog must be a structure with .contents to be displayed
	// all other structure data (meta data) is currently ignored
	// actionIcons = props.actionIcons;
	// console.log(actionIcons);
	// const [secondary,secondaryFunction] = props.secondary
	//   function returning [label,function]
	//
	// if an id (from the catalog) or tid (tiny id) is provided, find it in the catalog
	// and use that as a starting point
	//		check for id in history.state (highest priority)
	//			if none, replaceHistory to have id of top of the catalog
	//		check for id and tid in the props
	//			id is used for internal navigation,
	//			tid is used for external direction
	//		on each render, if current state doesn't match,
	//			set ready to false
	//			change state 'start' to render the appropriate branch (in useEffect)

	console.log("CatalogList... tid " + props.tid + ", id " + props.id);

	const [ready, setReady] = useState(false);
	const [start, setStart] = useState(props.catalog); // default starting point
	const nowReady = useRef(false); // can be set false in following code
	nowReady.current = ready; // set false to skip rendering on this pass
	const history = useHistory();
	const scrollDone = useRef(false);

	// const backStack = useRef([]);
	console.log(history.report());

	// process starting point to (portion of) catalog
	let id = history.state().id; // usually undefined on initial enter
	let tid = null;
	if (id === undefined) {
		// initial entry did not have id in history, check props
		id = props.id ? props.id : null; // convert undefined to null
		tid = props.tid ? props.tid : null; // convert undefined to null
		if (!id) id = props.catalog.id; // default to top of the catalog for now
		// when re-navigating to this page, we will ignore tid prop
		history.replaceState({ ...history.state(), id: id });
		console.log("history length " + history.length());
		console.log(history.state());
		if (tid && tid.length !== 5) tid = null; // ignore invalid tid
		if (tid) id = null; // tid wins on first mount
	}
	let tag = tid || id;
	let tagName = tag ? (tid ? "tinyId" : "id") : null;
	const useTag = useRef(tid !== null || (id && id > 1)); // use tag if it exists
	const tagTracker = useRef(null);
	console.log("CL tagName " + tagName + ", tag " + tag);
	console.log(" useTag " + useTag.current + " tracker " + tagTracker.current);

	if (!tag || tag === props.catalog.id || !useTag.current) {
		// useTag.current is false if not (yet) found
		if (start.id === props.catalog.id) {
			tagTracker.current = start.id; // already found
			// at top of tree (no id currently, or id is top of catalog)
			if (!ready) setReady(true); // minimize state transitions
			nowReady.current = true;
		} else {
			console.log("start.id wrong, reset to top of catalog ");
			setStart(props.catalog);
			if (!ready) setReady(true);
			nowReady.current = true;
		}
	} else {
		// start should be a container holding the target folder
		if (start[tagName] === tag) {
			// no seed to search further (rerendering)
			if (!ready) setReady(true);
			nowReady.current = true;
		} else {
			console.log(
				"CL start " +
					start.contents[0].name +
					" tag " +
					start.contents[0][tagName] +
					" length " +
					start.contents.length
			);
			tagTracker.current = null; // ensure another search
			// both of the following start false on first render
			// setReady(false);
			// nowReady.current = false; // immediately hide view
		}
	}
	console.log(" ready " + ready + " nowReady " + nowReady.current);
	console.log(
		"tagTracker " + tagTracker.current + " start len " + start.contents.length
	);

	function handleBack0() {
		// called when closing a navigated-to branch
		// if (backStack.current.length > 0 && props.handleBack)
		props.handleBack();
	}

	function onSelect0(node) {
		history.replaceState({ ...history.state(), s: window.scrollY });
		props.onSelect(node);
	}

	const pushStart = (node, setOpen) => {
		// change Catalog's List view from one branch/tree to another branch
		console.log("pushStart");
		if (setOpen) setOpen(true); // set new branch to open
		let newStart = { id: node.id, name: node.name, contents: [node] }; // outer container is not displayed
		setStart(newStart); // render branch
		let { tid, ...other } = history.state(); // remove tid just in case (probably not needed)
		useTag.current = true;
		tagName = "id"; // set up search values to
		tagTracker.current = node.id; // match this node
		const newState = { ...other, id: node.id }; // next starting id
		console.log(history.state());
		console.log("pushing next state");
		history.pushState(newState); // push a state corresponding to setStart navigation
		console.log(history.report());
	};

	useEffect(() => {
		console.log("useEffect 1");
		// ? condition on !ready?
		if (tag && tagTracker.current !== tag) {
			// tagTracker starts null
			tagTracker.current = tag; // evaluate only once
			console.log("find tag " + tagName + " value " + tag);
			let results = findByTag(props.catalog, tagName, tag);
			if (results) {
				let target = results[0] ? results[0] : results;
				let parent = results[1] ? results[1] : null;
				console.log("re-starting at tag " + tagName + " " + tag);
				useTag.current = true;
				let { tid, id, ...oldState } = history.state(); // remove tid, id
				if (target.contents) {
					// if a folder, prep to render that branch
					let newStart = {
						id: target.id,
						name: target.name,
						contents: [target]
					};
					tagTracker.current = target.id; // history tracks by id
					setStart(newStart);
					if (history.state().id !== target.id) {
						console.log(newStart);
						console.log("before folder push length " + history.length());
						// if same, this was a +/- navigation, else, record change
						history.pushState({ ...oldState, id: target.id });
						console.log("after push length " + history.length());
					}
					setReady(true);
					nowReady.current = true;
				} else {
					// else prep to hand off to a presenter, and upon
					// return, render target's parent folder
					let parentId = parent ? parent.id : 1; // if error in getting parent, use top
					console.log("before item push length " + history.length());
					history.pushState({ ...oldState, id: parentId, sid: target.id });
					console.log(history.report());
					props.onSelect(target); // re-direct to node presenter; App will add to history
				}
			} else {
				useTag.current = false;
				setReady(true);
				nowReady.current = true;
			}
		} else {
			setReady(true);
			nowReady.current = true;
		}

		function findByTag(node, tagname, value) {
			// console.log("findTinyId " + (node && node.name ? node.name : ""));
			// depth first, first found
			if (node[tagname] && node[tagname] === value) return node;
			// console.log(
			// 	"searching in " +
			// 		node.name +
			// 		" contents for " +
			// 		tagname +
			// 		": " +
			// 		node[tagname]
			// );
			if (node.contents) {
				for (let i = 0; i < node.contents.length; i++) {
					let result = findByTag(node.contents[i], tagname, value);
					if (result) {
						if (result[1]) return result;
						else return [result, node];
					}
				}
			}
			return null;
		}
	}, [history, props, ready, tag, tagName]);

	let nodes = start.contents;
	if (ready && nowReady.current) {
		// get fresh status data on every ready render
		const status = props.getStatus ? props.getStatus(start) : null;
		// console.log(status);
		for (let i = 0; i < nodes.length; i++) {
			let child = nodes[i];
			child.status = status ? status[i] : 0;
		}
		console.log(nodes);
		if (!scrollDone.current) {
			let state = history.state();
			if (state.sid) {
				var item = document.getElementById("" + state.sid);
				if (item) {
					setTimeout(() => item.scrollIntoView({ block: "center" }), 200);
					scrollDone.current = true;
				}
			} else if (state.s) {
				if (window.scrollY > state.s - 10) scrollDone.current = true;
				else setTimeout(() => window.scrollTo({ top: state.s }), 100);
			} else scrollDone.current = true;
		}
	}
	let depth = nodes.length === 1 || tid !== null ? 0 : 1; // controls opening of fat first nodes
	console.log("CL ready? " + ready + " " + nowReady.current);
	return ready && nowReady.current ? (
		<Grid container spacing={{ xs: 0.5, sm: 2, md: 3 }}>
			<Grid key="0" item xs={12} sm={9} md={6}>
				{nodes.map((node) => {
					// for now assumes topmost is an array of folders, no leaves
					// to start at a branch, wrap it into {contents:[start]}
					return (
						<CatalogBranch
							node={node}
							key={"CB-" + node.id}
							depth={depth}
							pushStart={pushStart}
							handleBack={handleBack0}
							getStatus={props.getStatus}
							offline={props.offline}
							getAvailability={props.getAvailability}
							onSelect={onSelect0}
							onAction={props.onAction}
							actionIcons={props.actionIcons}
						/>
					);
				})}
			</Grid>
		</Grid>
	) : (
		<div
			dangerouslySetInnerHTML={{
				__html: "<span class='loader'></span>"
			}}
		/>
	);
});

export default CatalogList;
