import React from "react";
import Typography from "@mui/material/Typography";
import { ReactComponent as Books } from "../books.svg";
import IconButton from "@mui/material/IconButton";
import SvgIcon from "@mui/material/SvgIcon";
import { useTranslation } from "react-i18next";
import LastViewed from "./LastViewed";
// import CatalogFilter from "./CatalogFilter";

const Home = React.forwardRef((props, ref) => {
	const { t } = useTranslation();

	let showRecent = props.recent === "true";

	let standalone =
		window.matchMedia("(display-mode: standalone)").matches ||
		window.navigator.standalone ||
		document.referrer.includes("android-app://");

	const log = "";
	// const date = new Date(Date.UTC(2024, 1, 2, 3, 4, 5));
	// log.current +=
	// 	"\nTimezoneOffset " +
	// 	date.getTimezoneOffset() +
	// 	"\n" +
	// 	navigator.languages +
	// 	+"\n" +
	// 	navigator.language +
	// 	" " +
	// 	navigator.browserLanguage;
	// 	(navigator.AppName || "") +
	// 	"\n" +
	// 	navigator.userAgent +
	// 	"\n" +
	// 	navigator.appVersion +
	// 	"\nwidth " +
	// 	window.screen.width +
	// 	" height " +
	// 	window.screen.height +
	// 	"\npixel ratio " +
	// 	window.devicePixelRatio;

	return (
		<div ref={ref}>
			<Typography variant="h6" gutterBottom sx={{ mt: 9 }}>
				{t("HomePage")}
			</Typography>
			{showRecent && <LastViewed callback={props.selected} />}

			<IconButton
				onClick={() => {
					props.handlePageSelect(1);
				}}
			>
				<SvgIcon viewBox="0 0 340 340" style={{ fontSize: 72 }}>
					<Books />
				</SvgIcon>
			</IconButton>

			{/* <CatalogFilter /> */}

			<Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
				{t("Welcome")}
			</Typography>
			{!standalone && (
				<div>
					(Final Text TBD.)
					<br />
					This web page can be installed so that you can use it offline. In
					Chrome, at the top right of the address bar, click Install. Follow
					the onscreen instructions to install HeartLMD.
				</div>
			)}
			{standalone && (
				<div>
					(Final Text TBD.)
					<br />
					This web app has been installed, and can be used offline to view
					items already downloaded.
				</div>
			)}
			<div>{log.current}</div>
		</div>
	);
});

export default Home;
